

import {IStep} from '@/services/components/INavigationStep';
import {Component, Prop, Vue} from 'vue-property-decorator';

@Component({})
export default class SigStepNavStep extends Vue {
  @Prop() private step!: IStep;
  @Prop() private currentstep!: number;
  @Prop() private maxstep!: number;

  private indicatorclass() {
    return {
      merForceActive: this.step.id === this.currentstep && this.step.id !== this.maxstep,
      active: this.step.id === this.currentstep,
      complete: this.isComplete(),
      disabled: this.step.disabled,
    };
  }

  private isComplete(): boolean {
    return this.currentstep >= this.step.id;
  }

  private isDisabled(): boolean {
    return this.step.disabled;
  }

  private goTo(stepNumber: number) {
    if (!this.isDisabled()) {
      this.$emit('update-nav-step', stepNumber);
    }
  }
}
