

import {IStep} from '@/services/components/INavigationStep';
import {Component, Prop, Vue} from 'vue-property-decorator';
import SigStepNavStep from '@/components/user/SigStepNavStep.vue';

@Component({
  components: {SigStepNavStep},
})
export default class SigStepNav extends Vue {
  @Prop() private steps!: IStep[];
  @Prop() private currentstep!: number;
  @Prop() private maxstep!: number;

  public updateCurrentStep(stepNumber: number) {
    this.$emit('update-step', stepNumber);
  }
}
