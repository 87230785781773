import {IUserResponse} from '@/services/dto/IUserResponse';

export default class LoginUtils {

    /**
     * Replacement de caractères  spéciaux
     * @param str
     * @private Chaine avec caractère replacé
     */
    public static cleanUpAccentsAndSpaces(str: string) {
        str = str.replace(/[àâä]/g, 'a');
        str = str.replace(/[éèêë]/g, 'e');
        str = str.replace(/[îï]/g, 'i');
        str = str.replace(/[ôö]/g, 'o');
        str = str.replace(/[ùûü]/g, 'u');
        str = str.replace(/[ç]/g, 'c');
        str = str.replace(/[ ]/g, '_');
        return str;
    }

    /**
     * Construit le plan de nommage par défaut pour le compte utilisateur passé en paramètre.
     * Le plan de nommage sert pour les logins et emails
     *
     * @example
     *  - Notaire = prenom.nom
     *  - Collaborateur ou entité = prenom.nom.crpcen
     *
     * @param role Le role de l'utilisateur
     * @param entitylogin Le nom de l'entité (si entité)
     * @param firstName  Le prénom de l'utilisateur (si personne physique)
     * @param lastName Le nom de l'utilisateur (si personne physique)
     * @param crpcen Le CRPCEN de l'office
     */
    public static getDefaultNamingConvention(
        role: string,
        entitylogin: string | undefined,
        firstName: string | undefined | null,
        lastName: string | undefined | null,
        crpcen: string,
    ) {
        let formattedLogin: string;
        const isEntity: boolean = (role.length > 0 && role === 'ROLE_ENTITY');
        const isSelfcare: boolean = (role.length > 0 && role === 'ROLE_SELFCARE_NOTAIRE');
        // On supprime le premier 0 du crpcen
        if (crpcen.length > 0) {
            const firstChar: string = crpcen.substring(0, 1);
            if (firstChar === '0') {
                crpcen = crpcen.substring(1);
            }
        }

        // Plan de nommage
        //  - Notaire= prenom.nom
        //  - Collaborateur ou entité= prenom.nom.crpcen
        const separator: string = '.';
        if (isEntity) {
            formattedLogin = entitylogin !== undefined ? entitylogin : '';
            if (crpcen !== undefined && crpcen.length > 0) {
                if (entitylogin != null && entitylogin.length > 0
                    && separator !== entitylogin.substring(entitylogin.length - 1, entitylogin.length)) {
                    formattedLogin = formattedLogin + separator;
                }
                formattedLogin = formattedLogin + crpcen;
            }
        } else {
            formattedLogin = firstName !== undefined && firstName !== null ? firstName : '';
            if (firstName !== undefined && firstName !== null && firstName.length > 0
                && separator !== firstName.substring(firstName.length - 1, firstName.length)) {
                formattedLogin = formattedLogin + separator;
            }
            formattedLogin = formattedLogin + lastName;
            if (!isSelfcare) {
                if (crpcen !== null && crpcen.length > 0) {
                    if (lastName !== undefined && lastName != null && lastName.length > 0
                        && separator !== lastName.substring(lastName.length - 1, lastName.length)) {
                        formattedLogin = formattedLogin + separator;
                    }
                    formattedLogin = formattedLogin + crpcen;
                }
            } else {
                // On regarde si le dernier caractère est un point (on le supprime si oui)
                const lastChar: string = formattedLogin.substring(formattedLogin.length - 1, formattedLogin.length);
                if (lastChar === separator) {
                    formattedLogin = formattedLogin.substring(0, formattedLogin.length - 1);
                }
            }
        }

        return LoginUtils.cleanUpAccentsAndSpaces(formattedLogin.toLowerCase());
    }

    /**
     * Construit le plan de nommage par défaut pour le compte utilisateur passé en paramètre.
     * Le plan de nommage sert pour les logins et emails
     * Remarque : Il s'agit ici du login ballade.
     * @example
     *  - Notaire = prenom.nom
     *  - Collaborateur ou entité = prenom.nom.crpcen
     *
     * @param {Object} account - Le compte utilisateur
     * @param {String} account.lastName - Le nom de l'utilisateur (si personne physique)
     * @param {String} account.firstName - Le prénom de l'utilisateur (si personne physique)
     * @param {String} account.entityName - Le nom de l'entité (si entité)
     * @param {String} account.role - Le role de l'utilisateur
     * @param {String} account.crpcen - Le CRPCEN de l'office
     * @return {string}
     */
    public static getDefaultNamingConventionFromObject(account: IUserResponse) {
        const role: string = account.authorities !== undefined && account.authorities.length > 0 ?
            account.authorities[0] : '';
        return LoginUtils.getDefaultNamingConvention(role, account.entityName, account.firstName,
            account.lastName, account.crpcen);
    }
}
