
import {Component, Prop, Watch} from 'vue-property-decorator';
import SigH4Title from '@/components/user/SigH4Title.vue';
import {IAccountCreation, IEmailCreation} from '@/services/dto/user/ICreateUser';
import {ISigInputTextDto} from '@/components/user/ISigInputTextDto';
import {IUserServicePriceResponse} from '@/services/dto/user/IUserServicePriceResponse';
import UserCreationService from '@/services/UserCreationService';
import AbstractComponentVue from '@/services/AbstractComponentVue';
import InputValidationUtils from '@/utils/InputValidationUtils';
import {services} from '@/main';
import PatternUtils from '@/utils/PatternUtils';

const userCreationService = new UserCreationService();

@Component({
  components: {SigH4Title},
})
export default class UserCreationStep2 extends AbstractComponentVue {
  @Prop() private accountCreation!: IAccountCreation;
  // Cas création mail - sur utilisateur existant
  @Prop({required: true}) private entity!: boolean;
  private baseUrl = services.config.config.$api_url;
  @Prop() private voidNextStep!: (email?: IEmailCreation, userSrpDto?: IUserServicePriceResponse) => void;
  @Prop() private previousStep!: () => void;
  @Prop() private cancelAction!: () => void;
  // Non valorisé pour la création du service mail
  @Prop() private userSrpDto!: IUserServicePriceResponse;

  private mailInputText: ISigInputTextDto = {
    value: '',
    hasError: false,
    msgError: '',
  };

  private isMailChecked: boolean = true;
  private webmailActive: boolean = false;
  private contractAccepted: boolean = false;
  private smartphoneActive: boolean = false;
  private addressSuffix: string = '@notaires.fr';

  /**
   * Mise à jour du mail dans le composant.
   * @param mail nouvelle addressSuffix
   * @param smartphone active ou désactivé par défaut
   */
  public updateMailAndSmartphone(mail: string, smartphone: boolean) {
    this.mailInputText.value = mail;
    this.smartphoneActive = smartphone;
    this.webmailActive = true;
  }

  private created() {
    const mail: IEmailCreation | null = this.accountCreation !== undefined ? this.accountCreation.email : null;
    if (mail !== null) {
      // cas de la création utilisation
      this.mailInputText.value = mail.addressPrefix;
      this.webmailActive = mail.webmailActive;
      this.contractAccepted = mail.contractAccepted;
      this.smartphoneActive = mail.smartphoneActive;
    }
  }

  @Watch('mailInputText.value')
  private watchMail() {
    const msgPatternError: string = 'Le format de saisie est erroné, veuillez consulter la bulle d\'aide.';
    InputValidationUtils.validationInputText(this.mailInputText, true, 4, 49,
        PatternUtils.SIG_PATTERNS.login, msgPatternError, false);
  }

  /**
   * Condition d'activation du bouton de l'étape 3. Récapitulatif
   */
  private disabledButtonToStep3(): boolean {
    return (this.mailInputText.hasError || !this.contractAccepted) && this.isMailChecked;
  }
  /**
   * webService vérification n'est pas déjà utilisée dans le system.
   */
  private checkIfMailAlreadyUsed() {
    const email = this.mailInputText.value + this.addressSuffix;
    this.showModal('modal-traitement-cours');
    userCreationService.checkMailAlreadyUsed(email)
        .then((response: any) => {
          if (response.status === undefined || response.status !== 200) {
            console.error(String(response));
            this.validationAlreadyMailUsed(this.mailInputText);
            this.hideModal('modal-traitement-cours');
          } else {
            // Cas next step
            this.callVoidNextStep();
          }
        })
        .catch(((res: any) => {
          console.error(res);
          this.validationAlreadyMailUsed(this.mailInputText);
          this.hideModal('modal-traitement-cours');
        }));
  }

  /**
   * Appel callback si présent
   */
  private callVoidNextStep() {
    if (this.voidNextStep !== undefined) {
      const email: IEmailCreation | undefined = this.isMailChecked ? {
        addressPrefix: this.mailInputText.value,
        addressSuffix: this.addressSuffix,
        contractAccepted: this.contractAccepted,
        smartphoneActive: this.smartphoneActive,
        webmailActive: this.webmailActive,
      } : undefined;
      this.voidNextStep(email, this.userSrpDto);
    }
  }

  /**
   * Erreur de validation, e-mail déjà utilisé
   * @param emailInputText inputMail
   */
  private validationAlreadyMailUsed(emailInputText: ISigInputTextDto) {
    emailInputText.hasError = true;
    emailInputText.msgError = 'L\'adresse e-mail que vous avez choisie existe déjà. Veuillez en choisir une autre.';
  }

}
