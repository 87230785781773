import {Vue} from 'vue-property-decorator';
import {services} from '@/main';
import {IAccountCreation} from '@/services/dto/user/ICreateUser';
import {INavigationStep} from '@/services/components/INavigationStep';

export default class UserCreationService {

	/**
	 * Renvoie le prix du service Mail
	 * @private
	 */
	public getPriceForUserServiceMail(): Promise<any> {
		return services.restService.callGet('/api/v1/user-services/MAIL/price');
	}

	/**
	 * Web service permettant de vérifier la présence d'un login
	 * @param loginToCheck email a verifier
	 */
	public checkLoginAlreadyUsed(loginToCheck: string): Promise<any> {
		return services.restService.callGet('/api/v1/availability/login/' + loginToCheck + '/MER');
	}

	/**
	 * Web service permettant de vérifier la présence d'une adresse mail
	 * @param emailToCheck email a verifier
	 */
	public checkMailAlreadyUsed(emailToCheck: string): Promise<any> {
		return services.restService.callGet('/api/v1/availability/email/' + emailToCheck);
	}

	/**
	 * Web service de création de l'utilisateur
	 * @param createUserForm
	 */
	public createAccount(account: IAccountCreation): Promise<any> {
		return services.restService.callPost('/api/v1/accounts/', account);
	}

	/**
	 * Initialisation de la Navigation de la création de l'utilisateur.
	 */
	public initNav(): INavigationStep {

		if(Vue.prototype.isAdnov() && !Vue.prototype.isAll()){
			return {
				currentstep: 1,
				maxstep: 2,
				steps: [
					{
						id: 1,
						title: '1. Informations utilisateur',
						disabled: false,
					},
					{
						id: 2,
						title: '2. Récapitulatif',
						disabled: true,
					},
				],
			};
		}else{
			return {
				currentstep: 1,
				maxstep: 3,
				steps: [
					{
						id: 1,
						title: '1. Informations utilisateur',
						disabled: false,
					},
					{
						id: 2,
						title: '2. Messagerie',
						disabled: true,
					},
					{
						id: 3,
						title: '3. Récapitulatif',
						disabled: true,
					},
				],
			};
		}
	}

}
