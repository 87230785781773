
import {Component, Prop, Vue} from 'vue-property-decorator';
import SigH4Title from '@/components/user/SigH4Title.vue';

@Component({
  components: {SigH4Title},
})
export default class UserCreationStep3 extends Vue {
  @Prop() private activationCode!: string;
  @Prop() private mail!: string;
  @Prop() private login!: string;
  @Prop() private roleType?: string;
  @Prop() private hasCheckedMail !: boolean;
  @Prop() private hasCheckedAdmin !: boolean;
  @Prop() private webmailActive!: boolean;
  @Prop() private smartphoneActive!: boolean;
  @Prop() private addressMailValidationRequested?: boolean;
  // Propriétés cas création des userservice
  @Prop() private userServiceNameCreated!: string;

  public ihmBadgeVariant(val: boolean): string {
    return val ? 'warning' : 'danger';
  }

  public ihmBadgeContent(val: boolean): string {
    return val ? 'En cours d’activation' : 'Non Activé';
  }

  public get ihmBadgeAdminVariant(): string {
    return this.hasCheckedAdmin ? 'success' : 'danger';
  }

  public get ihmBadgeAdminContent() {
    return this.hasCheckedAdmin ? 'Activé' : 'Non Activé';
  }

  private printSummary(): void {
    window.print();
  }

  private get isRoleCollab(): boolean {
    return this.roleType !== undefined && this.roleType === 'ROLE_SELFCARE_COLLABORATEUR';
  }

  private get isEntity(): boolean {
    return this.roleType !== undefined && this.roleType === 'ROLE_ENTITY';
  }

}
